import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"

import { graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import Img from "gatsby-image"

import Header from "../components/header"
import Section from "../components/section"
import TwmButton from "../components/twm-button"

import {
  PageWrapper,
  TextWrapper,
  HeadingWrapper,
  FlexWrapper,
  BoxWrapper,
} from "../components/contentwrappers"

export default function GuidesPage({ data }) {
  const { guides } = data.guide

  return (
    <div>
      <Layout>
        <SEO title="Guides" />
        <BackgroundImage
          fluid={data.newspaperImage.childImageSharp.fluid}
          alt="A newspaper"
        >
          <Header headerText="Guides"></Header>
        </BackgroundImage>
        <Section type="wide">
          <p>
            As well as our <Link to="/finding-true-wealth">blog articles</Link>,
            we produce guides from time-to-time. You can find previous guides
            below:
          </p>

          <FlexWrapper blogs>
            {guides.map(guide => (
              <BoxWrapper key={guide.id} width={"narrow"}>
                <h3>{guide.frontmatter.title}</h3>

                <small>{guide.frontmatter.date}</small>
                <a href={guide.frontmatter.fileUrl} target="_blank">
                  <Img
                    fluid={
                      guide.frontmatter.featuredImage.childImageSharp.fluid
                    }
                  />
                </a>
              </BoxWrapper>
            ))}
          </FlexWrapper>
        </Section>
      </Layout>
    </div>
  )
}

export const pageQuery = graphql`
query GuideQuery {
  guide: allMarkdownRemark(
    filter: { frontmatter: { type: { eq: "guide" } } }
    sort: { fields: frontmatter___date, order: DESC }
  ) {
    guides: nodes {
      fields {
        slug
      }
      frontmatter {
        date(formatString: "MMMM YYYY")
        title
        fileUrl
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 300, maxHeight: 424) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      excerpt
      id
    }
  }
  newspaperImage: file(relativePath: { eq: "bookcase.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1800) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
